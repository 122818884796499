.selection-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px !important;
}

.right-side-content{
    display: flex;
    align-items: center;
}

.selection-div-element{
    margin: 0px 5px;
    cursor: pointer;
}

.search_field{
    margin-top: 24px;
}

.spinner-div{
    position:"relative"; display:"block"; width:'100%';
}