.chart-title{
    color: #8080cf;
    font-size: 15px !important;
}

.chart-header-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px 0px 8px;
}

.filter-div{
    display: flex;
    align-items: center;
}

.filter-text{
    font-size: 18px;
    /* font-weight: 600; */
    margin-right: 6px;
    color: #8080cf;
}

.chart-div{
    position: relative;
    height: 40vh;
    width: 40vw;
}

.no-data-message{
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 100px;
    z-index: 20;
    color: #898989;
}

.card-div{
    text-decoration: none !important;
}
.card-style{
    font-size: 14px !important;
}

.card-text{
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-time{
    font-size: 12px;
    color: #8080cf;
}
.pagination-div { margin: "3% 0"; justifyContent: "center"; display: "flex" }

.noticiation-content{
    height: 50vh;
    overflow: auto;
}