div[aria-labelledby="alert_dialog_title"] {
  max-width: 100% !important;
  width: 70% !important;
  padding: 1% 3%;
  border-radius: 5px;
}
.questionAccordian:before {
  display: none !important;
}
.lesson_title {
  width: 75% !important;
  margin-left: -1%;
}
