#long-menu > .MuiMenu-paper {
  right: 0 !important;
  left: 80px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: flex;
    width: 100%;
}