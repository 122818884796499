/*Slider*/
.carousel-root .carousel-status {
    display: none;
}
.landing-carousel {
    margin: 10px 0 0;
    border-radius: 8px;
    overflow: hidden;
}
.landing-carousel .carousel-item {
    padding-bottom: 40%;
}
.landing-carousel .carousel-item::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.35);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}
.home-carousel .carousel-item {
    padding-bottom: 46%;
}

.carousel-item {
  position: relative;
}
.carousel-item img {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
.carousel-item .carousel-caption {
  position: absolute;
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  top: 50%;
  z-index: 9;
  text-align: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}
.carousel-item .carousel-caption p {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: #ffffff;
  margin: 0 0 40px;
}
.carousel-item .carousel-caption a {
    padding: 8px 24px;
    color: #fff;
    background: #00D7B9;
    border-radius: 8px;
    margin-top: 300px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    /* display: inline-block; */
    display: inline;
    float: right;
}
.home-carousel .slider-wrapper {
    border-radius: 12px;
}
@media(max-width: 768px) {
    .landing-carousel .carousel-item {
        padding-bottom: 65%;
    }
    .home-carousel .carousel-item {
        padding-bottom: 65%;
    }
    .carousel-item .carousel-caption p {
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 20px;
    }
    .carousel-item .carousel-caption a {
        font-size: 14px;
        line-height: 18px;
        padding: 6px 12px;
        margin-top: 150px;
    }
}


/*Why*/
.why-card {
    background: #FAFAFA;
    padding: 12px;
    box-shadow: 0px 2.5px 0px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 100%;
}
.why-card .number {
    margin-right: 12px;
}
.why-card .number span {
    width: 48px;
    height: 48px;
    background: #00D7B9;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}
.why-card .why-content h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0000A0;
    margin: 0 0 8px;
}
.why-card .why-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin: 0;
}
h2.page-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}
.greet-message {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    color: #000;
    opacity: .4;
}
.section-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 20px;
}
.section-title-wrap .section-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
}
.section-title-wrap a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #0000A0;
    text-decoration: none;
}

.scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: calc(100% + 24px);
    margin-left: -24px;
}
.scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }
.scrolling-wrapper.four-column .s-card {
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 294px;
    padding-left: 24px;
    padding-bottom: 10px;
}
.scrolling-wrapper.three-column .s-card {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    min-width: 294px;
    padding-left: 24px;
    padding-bottom: 10px;
}
.home-sidebar {
    margin-left: 24px;
}

.upcoming-webinars .s-card {
    width: 100%;
    margin: 0 0 24px;
}
@media (max-width: 1080px) {
    .upcoming-webinars .s-card {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        min-width: 294px;
        padding-left: 24px;
    }
    .home-sidebar {
        margin: 0;
    }
}
@media (min-width: 1081px) {
    .home-sidebar .scrolling-wrapper {
        flex-wrap: wrap;
        overflow-x: hidden;
        margin-left: 0;
    }
}

.landing-carousel .carousel .control-dots .dot {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 8px;
    background-color: #fff;
    height: 8px;
    width: 8px;
    box-shadow: none;
    margin: 0 4px;
}
.home-carousel .carousel .control-dots .dot {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: #00d7b9;
    height: 10px;
    width: 10px;
}
.landing-carousel .carousel-item img{
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    object-fit: contain;    
}