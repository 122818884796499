/* .query-card {
    background: #FAFAFA;
    cursor: pointer;
    margin: 28px 0px;
    border-radius: 8px;
    padding: 12px 12px 12px 44px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
} */
.query-card-admin{
    background-color: white !important;
}
.query-card .query-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
}
.query-card .query-card-head .query-card-head-left {
    display: flex;
    align-items: center;
}
.query-card .query-card-head .query-card-head-left h5 {
    margin: 0 8px;
    color: #0000A0;
}
.query-card .query-card-head .query-card-head-right {
    color: #0000A0;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}
.query-card .query-card-body {
    padding-top: 16px;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}
.query-card .query-card-body h5 {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 16px;
    color: #8080CF;
}
.query-card .query-card-body p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    opacity: .7;
    margin: 0 0 16px;
}
.query-card .query-card-footer {
    padding-top: 16px;
}
.reply-textarea textarea {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
}